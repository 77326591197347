<i18n>
[
    "global__supportEmailAddress--escaped",
    "help__chat--title",
]
</i18n>
<template>
    <div
        v-if="intercomIsEnabled"
        v-on-clickaway="closeMenu"
        class="c-intercom-launcher"
        :class="{
            'c-intercom-launcher--hidden': shouldHideIcon,
        }"
    >
        <Transition name="slide-up">
            <div v-if="isOpen" class="c-intercom-launcher__menu-container">
                <div class="c-intercom-launcher__menu">
                    <template v-if="activeMessage">
                        <h5
                            v-if="activeMessage.label"
                            class="c-intercom-launcher__title u-spacer--1"
                        >
                            {{ activeMessage.label }}
                        </h5>
                        <p class="u-spacer--5">
                            {{ activeMessage.message }}
                        </p>
                    </template>
                    <h5
                        v-else
                        class="c-intercom-launcher__title u-spacer--1"
                    >
                        {{ $t('help__chat--title') }}
                    </h5>
                    <ul>
                        <li
                            v-for="link in helpLinks"
                            :key="link.linkPath"
                            class="u-spacer--1"
                            @click="e => handleLinkClick(e, link)"
                        >
                            <BaseLink :to="handleLinkPath(link)" :target="link.openInNewWindow ? '_blank' : '_self'">
                                {{ link.linkTitle }}
                            </BaseLink>
                        </li>
                    </ul>
                    <div class="c-intercom-launcher__menu-triangle"></div>
                </div>
            </div>
        </Transition>
        <Transition name="slide-up" mode="out-in">
            <div class="c-intercom-launcher__img-container" @click="toggleOpen">
                <AppIcon
                    v-if="isOpen"
                    key="open"
                    icon="global--close"
                    size="12px"
                    color="white"
                />
                <img
                    v-else
                    key="close"
                    :src="IntercomWaveImage"
                    alt="Close Intercom"
                    class="c-intercom-launcher__hand-img"
                >
            </div>
        </Transition>
    </div>
</template>

<script>
import {
    mapActions,
    mapGetters,
    mapState,
} from 'vuex';

import runtimeConfig from '~config/config';

import { SCROLL_DIRECTION } from '~coreModules/browser/js/browser-constants';
import { LINK_CLICKED } from '~coreModules/core/js/global-event-constants';

import { initializeIntercom } from '~modules/intercom/js/intercom-utils';
import { SELECT_LINK_TYPES } from '~modules/analytics/js/analytics-constants';

import { BROWSER_MODULE_NAME } from '~coreModules/browser/js/browser-store';
import { SITE_SETTINGS_MODULE_NAME } from '~modules/site-settings/js/site-settings-store';
import { GLOBAL_EVENT } from '~coreModules/core/js/store';
import { COMPLIANCE_MODULE_NAME } from '~coreModules/compliance/js/compliance-store';
import { PROFILE_MODULE_NAME } from '~modules/profile/js/profile-store';

import { getIntercomChatToken } from '~modules/profile/js/profile-api';

import IntercomWaveImage from '~images/intercom__wave.png';

export default {
    name: 'IntercomLauncher',
    directives: {
        ...(process.env.VUE_ENV === 'client' ? {
            // eslint-disable-next-line global-require
            onClickaway: require('vue3-click-away').directive,
        } : {
            onClickaway: {},
        }),
    },
    props: {
        shouldHideIntercomIcon: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            IntercomWaveImage,
            intercomIsEnabled: runtimeConfig.features.intercom,
            isOpen: false,
        };
    },
    computed: {
        ...mapState(BROWSER_MODULE_NAME, [
            'scrollDirection',
        ]),
        ...mapState(SITE_SETTINGS_MODULE_NAME, {
            anonymousLinks: state => state.contentfulAppConfig?.anonymousHelpLinks,
            messages: state => state.contentfulAppConfig?.toolTips,
            registeredLinks: state => state.contentfulAppConfig?.registeredHelpLinks,
        }),
        ...mapState(PROFILE_MODULE_NAME, {
            profileId: state => state.profileId,
        }),
        ...mapGetters(COMPLIANCE_MODULE_NAME, [
            'shouldTrack',
        ]),
        ...mapGetters([
            'loggedIn',
        ]),
        activeMessage() {
            const targetMessage = this.messages.find(message => `/${message.slug}` === this.$route.path);
            return targetMessage || null;
        },
        helpLinks() {
            return this.loggedIn ? this.registeredLinks : this.anonymousLinks;
        },
        shouldHideIcon() {
            return this.shouldHideIntercomIcon || (this.scrollDirection === SCROLL_DIRECTION.DOWN && !this.isOpen);
        },
    },
    async created() {
        if (process.env.VUE_ENV === 'client' && runtimeConfig.features.intercom && this.shouldTrack) {
            /* eslint-disable */
            (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;
            s.src='https://widget.intercom.io/widget/' + runtimeConfig.intercomAppId;
            var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
            /* eslint-enable */

            let userHash = null;
            if (this.loggedIn) {
                const { intercomToken } = await getIntercomChatToken(this.$r15Svc);
                userHash = intercomToken;
            }

            initializeIntercom(this.loggedIn, this.profileId, userHash);
        }
    },
    mounted() {
        if (runtimeConfig.features.intercom && this.shouldTrack) {
            // prefer hook instead of as part of openMessenger() b/c it helps our
            // icon disappear at a cleaner time in the animation cycle
            window.Intercom('onShow', () => {
                this.isOpen = false;
            });
        }
    },
    methods: {
        ...mapActions({
            trackGlobalEvent: GLOBAL_EVENT,
        }),
        handleLinkClick(event, clickedLink) {
            this.handleChatClick(clickedLink?.linkPath ?? '');
            const { shouldOpenChat } = clickedLink;
            if (shouldOpenChat) {
                this.openMessenger();
                event.preventDefault();
            } else {
                this.toggleOpen();
            }
        },
        openMessenger() {
            if (this.shouldTrack) {
                window.Intercom('show');
            } else {
                window.location.href = `mailto:${this.$t('global__supportEmailAddress--escaped')}`;
            }
        },
        closeMenu() {
            if (this.isOpen) {
                this.isOpen = false;
            }
        },
        toggleOpen() {
            this.isOpen = !this.isOpen;

            if (this.isOpen) {
                this.handleChatClick('open_widget');
            }
        },
        handleChatClick(destination) {
            this.trackGlobalEvent({
                type: LINK_CLICKED,
                data: {
                    type: SELECT_LINK_TYPES.CHAT,
                    destination,
                },
            });
        },
        handleLinkPath(link) {
            const { shouldOpenChat } = link;
            if (shouldOpenChat) {
                return this.$route.fullPath;
            }

            return link.linkPath;
        },
    },
};
</script>

<style lang="scss">
    .c-intercom-launcher {
        @include dropshadow;

        $triangleHeight: 12px;
        $menuPadding: $nu-spacer-2;
        $transitionDuration: 400ms;

        background-color: $nu-primary;
        border-radius: 100%;
        bottom: $nu-spacer-3;
        height: 48px;
        left: $nu-spacer-3;
        position: fixed;
        width: 48px;
        z-index: map-get($zindex, intercom-launcher);
        transition: all 0.5s ease;

        &--hidden {
            opacity: 0;
        }

        &__title {
            display: block;
        }

        &__img-container {
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }

        &__hand-img {
            width: 23px;
            pointer-events: none;
        }

        &__menu-container {
            bottom: 60px;
            height: 350px;
            position: absolute;
            width: 250px;
        }

        &__menu {
            background-color: $nu-white;
            bottom: $triangleHeight;
            box-shadow: 0 3px 3.5px 0 rgba(0, 0, 0, 0.25);
            padding: $menuPadding;
            position: absolute;
        }

        &__menu-triangle {
            background-color: $nu-white;
            bottom: -$menuPadding - ($triangleHeight / 2);
            box-shadow: 3px 3px 3.5px -2px rgba(0, 0, 0, 0.25);
            content: '';
            height: $triangleHeight;
            left: -$menuPadding - ($triangleHeight / 2) + (50px / 2);
            position: relative;
            transform: rotate(45deg);
            width: $triangleHeight;
        }

        &__link {
            color: $nu-primary;
            cursor: pointer;
        }

        .slide-up {
            &-enter-active,
            &-leave-active {
                transition: all $transitionDuration ease;
            }

            &-enter-to,
            &-leave {
                max-height: 500px;
                overflow: hidden;
            }

            &-enter,
            &-leave-to {
                overflow: hidden;
                max-height: 0;
            }
        }

        .fade-enter-active, .fade-leave-active {
            transition: opacity ($transitionDuration / 2) ease;
        }
        .fade-enter, .fade-leave-to {
            opacity: 0;
        }
    }
</style>
