import * as globalAnalyticsConstants from '~coreModules/analytics/js/analytics-constants';

export const PRODUCT_AFFILIATION = 'Nuuly Rent';

export const SUBSCRIPTION_PLAN_PRICE = 98.00;

export const PRODUCT_NAMES = {
    BONUS_ITEM: 'Bonus Item',
    GIFT_CARD: 'Gift Card',
};

export const PRODUCT_LIST_NAMES = {
    OPEN_BOX: 'open_box_id',
    ORDER_HISTORY: 'order_history',
    PDP_TRAY: 'pdp_tray_ymal',
    RECENT_ORDER: 'recent_order_id',
    REVIEW: 'reviews_question_set',
    SHOP_THE_LOOK: 'gateway_stl',
    CLOSET_SUBLIST: 'sublist',
    PUBLIC_CLOSET_SUBLIST: 'public_sublist',
    CLOSET_SUBLIST_TRAY: 'sublist_tray_closet',
    SIMILAR_ITEMS: 'similar_items',
};

export const PRODUCT_LIST_IDS = {
    CLOSET: 'default_list',
};

export const PRODUCT_IDS = {
    BONUS_ITEM: 'bonus_item',
};

export const PURCHASE_TYPES = {
    PLACE_ORDER: 'place_order',
    GIFT_CARD: 'gift_card',
};

export const CART_EVENTS = {
    ADD_BOX_ITEM: 'add_to_cart',
    DELETE_BOX_ITEM: 'remove_from_cart',
    UPDATE_BOX_ITEM: 'update_cart',
};

export const CAROUSEL_IDS = {
    CLOSET_SUBLIST_TRAY: 'default_closet_sublist_tray',
    YOU_MAY_ALSO_LIKE: 'you_may_also_like',
    CATALOG_IMAGE_VIEWER: 'catalog_image_viewer',
    REVIEWS: 'reviews_carousel',
    PDP_IMAGE_VIEWER: 'pdp_image_viewer',
    CLOSETED_PRODUCTS_TRAY: 'closeted_products_tray',
    RECENT_ORDER_TRAY: 'recent_order_tray',
    SUBLIST_TRAY_CLOSET: 'sublist_tray_closet',
    CURATION_NAV_ITEM: 'curation_nav_item',
    CURATION_TRAY: 'curation_tray',
};

export const CHECKOUT_TYPES = {
    ITEM: 'item',
    SHIPPING: 'shipping',
    SUBSCRIPTION: 'subscription',
    GIFT_CARD: 'gift_card',
    PREPURCHASE: 'prePurchase',
};

export const SELECT_CONTENT_TYPES = {
    ...globalAnalyticsConstants.SELECT_CONTENT_TYPES,
    BOX_LINK: 'box_link',
    REFERRAL_LINK: 'referral_link',
    REVIEW_PHOTO: 'review_photo',
    SIMILIAR_ITEMS_LINK: 'similar_items_link',
    SUBLIST_LINK: 'sublist_link',
    ADD_TO_PROSPECT_BOX: 'add_to_prospect_box',
    CURATION_NAV_ITEM: 'curation_nav_item',
};

export const REVIEW_FILTER_TYPES = {
    SORT: 'review_sort',
    FILTER: 'review_filter',
};

export const SUBSCRIPTION_FEES = 'subscription_fees';

export const REOCURRING_SUBSCRIPTION_CHARGES = 'reoccurring_subscription_charges';

export const REOCCURING_SUBSCRIPTION_QUANTITY = 1;

export const ACCOUNT_RENTAL_HISTORY = 'account_rental_history';

export const BONUS_ITEM_STEPPER_TYPES = {
    INCREMENT: 'increment',
    DECREMENT: 'decrement',
};

export const REDEEM_SUBSCRIPTION_COUPON_LOCATION_TYPES = {
    MODAL: 'modal',
    CARD: 'card',
};

export const SIGN_UP_TYPES = {
    ONBOARDING: 'onboarding',
    GIFT_CARD: 'gift-card',
};

export const REDEEM_GIFT_CARD_TYPES = {
    SUBSCRIPTION: 'subscription',
    ITEM: 'item',
    BONUS_ITEM: 'bonus_item',
    ACCOUNT: 'account',
    REVEAL: 'reveal',
    PREPURCHASE_ITEM: 'prePurchase_item',
};

export const PRODUCT_BUSINESSES = {
    RENT: 'rent',
    PREPURCHASE: 'prePurchase',
};

export const SHARE_CONTENT_TYPES = {
    SUBLIST: 'sublist',
    GIFT_CARD: 'gift_card',
    REFERRAL_LINK: 'referral_link',
};

export const COPY_TO_PASTE_BOARD = 'copyToPasteBoard';

export const FEEDBACK_TYPE_ISSUE_REPORT = 'issue-report';

export const SELECT_LINK_TYPES = {
    ...globalAnalyticsConstants.SELECT_LINK_TYPES,
    CHAT: 'chat',
    ISSUE_REPORT: 'issue_report',
    ISSUE_REPORT_LANDING_CELL: 'issue_report_landing_cell',
};
