import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { useGetters } from '~coreModules/core/js/composables/vuexHelpers';

// NU_FEATURE: useV2Account
// https://urbnit.atlassian.net/browse/TYP-27731
// use rentalAccountRouteData once it has been updated with the v2 values
import { rentalAccountRouteDataV2 } from '~modules/core/js/router-constants';
import { PROFILE_MODULE_NAME } from '~modules/profile/js/profile-store';

export default function useAccountNavLinks() {
    const { t } = useI18n();

    const { isNonSubscriber } = useGetters(PROFILE_MODULE_NAME, ['isNonSubscriber']);

    const accountNavChildren = rentalAccountRouteDataV2.find(route => route.meta?.accountNavContainer)?.children;

    const accountNavLinks = computed(() => ((accountNavChildren || [])
        .filter(({ component }) => !!component)
        .filter(({ meta }) => (!isNonSubscriber.value || (isNonSubscriber.value && !meta.hiddenForNonSubscriber)))
        .map(({ meta, name, path }) => ({
            displayName: t(meta.displaySlug),
            to: {
                name,
            },
            id: path,
            badgeText: meta.badgeTextSlug ? t(meta.badgeTextSlug) : '',
        }))
    ));

    return {
        accountNavLinks,
    };
}
