<template>
    <div class="c-header-links">
        <BaseLink
            v-for="({ to, displayName, badgeText }, index) in accountNavLinks"
            :key="index"
            :to="to"
            class="c-header-links__item o-text--body-copy u-text--black"
        >
            {{ displayName }}
            <span v-if="badgeText" class="c-header-links__badge o-text--label">
                {{ badgeText }}
            </span>
        </BaseLink>
    </div>
</template>

<script setup>
import useAccountNavLinks from '~modules/nav/js/composables/use-account-nav-links';

const { accountNavLinks } = useAccountNavLinks();

</script>

<style lang="scss">
.c-header-links {
    &__item {
        width: 100%;
        text-align: left;
        outline-offset: -4px;
        cursor: pointer;
        padding: $nu-spacer-2;
        background-color: $nu-white;
        transition: all 0.2s ease-in-out;

        border-bottom: 1px solid $nu-gray--light;

        &:hover {
            background-color: $nu-secondary-20;
            padding-left: $nu-spacer-3;
        }
    }

    &__badge {
        padding: $nu-spacer-1 $nu-spacer-1pt5;
        margin-left: $nu-spacer-1pt5;
        border-radius: $nu-spacer-0pt5;
        background-color: $placeholder-background;
    }
}
</style>
