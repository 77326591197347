export const REVIEW_SCHEMES = {
    CANCEL: 'request-cancel',
    EXTEND_PAUSE: 'extend-pause',
    PAUSE: 'request-pause',
    PRODUCT: 'product',
    RESUME_FROM_PAUSE: 'resume-from-pause',
    REFUND_ELIGIBLE: 'refund-eligible',
    ADDITIONAL_INFO: 'reported-issue-additional-info',
    WRONG_ITEM_RECEIVED: 'reported-issue-wrong-item-received',
    GARMENT_CONDITION: 'garment-condition',
};

export const REVIEW_WAITLIST_CONFIRMATION_SLUG = 'waitlist-confirmation';
