import { get, merge, pickBy, identity } from 'lodash-es';

import { getDiffDays } from '~coreModules/core/js/date-utils';
import { trackEvent } from '~coreModules/analytics/js/analytics-utils';
import { convertCentsToDollars } from '~coreModules/core/js/currency-utils';

import createAnalytics, {
    getUserData,
    TRACK_PRODUCT_FILTER_UPDATED,
} from '~coreModules/analytics/js/analytics-store';
import { BOX_MODULE_NAME } from '~modules/box/js/box-store';
import { PROFILE_MODULE_NAME } from '~modules/profile/js/profile-store';
import { SITE_SETTINGS_MODULE_NAME } from '~modules/site-settings/js/site-settings-store';

import { PRODUCT_FILTER_TYPES } from '~coreModules/analytics/js/analytics-constants';
import {
    SUBSCRIPTION_PLAN_PRICE,
    SELECT_CONTENT_TYPES,
    REVIEW_FILTER_TYPES,
    SIGN_UP_TYPES,
} from '~modules/analytics/js/analytics-constants';
import { USER_PREFERENCE_KEY_AVAILABILITY_FILTER } from '~modules/site-settings/js/site-settings-constants';
import { WAITLIST_LOCATIONS } from '~coreModules/profile/js/profile-constants';
import { ACCOUNT_CREATE, ACCOUNT_CREATE_WEB_VIEW } from '~routes/join/js/join-create-account-constants';

export { ANALYTICS_MODULE_NAME } from '~coreModules/analytics/js/analytics-store';

export const TRACK_SUBSCRIPTION_CREATE_COMPLETED = 'TRACK_SUBSCRIPTION_CREATE_COMPLETED';
export const TRACK_REFERRAL_SENT = 'TRACK_REFERRAL_SENT';
export const TRACK_REFERRAL_CLICKED = 'TRACK_REFERRAL_CLICKED';
export const TRACK_SUBSCRIPTION_COUPON_REDEEMED = 'TRACK_SUBSCRIPTION_COUPON_REDEEMED';
export const TRACK_SUBSCRIPTION_STATUS_UPDATED = 'TRACK_SUBSCRIPTION_STATUS_UPDATED';
export const TRACK_BOX_ITEM_STATUS_UPDATED = 'TRACK_BOX_ITEM_STATUS_UPDATED';
export const TRACK_SHIPPING_ADDRESS_UPDATED = 'TRACK_SHIPPING_ADDRESS_UPDATED';
export const TRACK_PROFILE_UPDATED = 'TRACK_PROFILE_UPDATED';
export const TRACK_EMAIL_UPDATED = 'TRACK_EMAIL_UPDATED';
export const TRACK_CATALOG_GRID_LAYOUT_UPDATED = 'TRACK_CATALOG_GRID_LAYOUT_UPDATED';
export const TRACK_CATALOG_AVAILABILITY_UPDATED = 'TRACK_CATALOG_AVAILABILITY_UPDATED';
export const TRACK_COUPON_CODE_APPLIED = 'TRACK_COUPON_CODE_APPLIED';
export const TRACK_BOX_ITEM_OUT_OF_STOCK = 'TRACK_BOX_ITEM_OUT_OF_STOCK';
export const TRACK_PRODUCT_TOUR_VIEWED = 'TRACK_PRODUCT_TOUR_VIEWED';
export const TRACK_CHECK_FOR_DROP_OFF = 'TRACK_CHECK_FOR_DROP_OFF';
export const TRACK_BONUS_ITEM = 'TRACK_BONUS_ITEM';
export const TRACK_RENEW_EARLY = 'TRACK_RENEW_EARLY';
export const TRACK_REPRINT_LABEL = 'TRACK_REPRINT_LABEL';
export const TRACK_REQUEST_BOX = 'TRACK_REQUEST_BOX';
export const TRACK_REVIEW_PHOTO_CLICK = 'TRACK_REVIEW_PHOTO_CLICK';
export const TRACK_BOX_LINK_CLICKED = 'TRACK_BOX_LINK_CLICKED';
export const TRACK_DELETE_SUBLIST = 'TRACK_DELETE_SUBLIST';
export const TRACK_CREATE_SUBLIST = 'TRACK_CREATE_SUBLIST';
export const TRACK_REVIEW_FILTER_UPDATED = 'TRACK_REVIEW_FILTER_UPDATED';
export const TRACK_REVIEW_SORT_UPDATED = 'TRACK_REVIEW_SORT_UPDATED';
export const TRACK_SHARE_CONTENT = 'TRACK_SHARE_CONTENT';
export const TRACK_VIEW_SIMILAR_CLICKED = 'TRACK_VIEW_SIMILAR_CLICKED';
export const TRACK_SUBLIST_LINK_SHARED = 'TRACK_SUBLIST_LINK_SHARED';
export const TRACK_LINK_SHARED = 'TRACK_LINK_SHARED';
export const TRACK_SIGN_UP_STARTED = 'TRACK_SIGN_UP_STARTED';
export const TRACK_SIGN_UP_COMPLETED = 'TRACK_SIGN_UP_COMPLETED';
export const TRACK_REDEEM_GIFT_CARD = 'TRACK_REDEEM_GIFT_CARD';
export const TRACK_ADD_TO_PROSPECT_BOX_CLICK = 'TRACK_ADD_TO_PROSPECT_BOX_CLICK';
export const TRACK_CURATION_CLICK = 'TRACK_CURATION_CLICK';

const getAppUserData = (rootState) => {
    const {
        profileId,
        subscriptionStatus,
        nextBillingCycle,
    } = rootState[PROFILE_MODULE_NAME];
    const availabilityPreference =
        get(rootState[SITE_SETTINGS_MODULE_NAME], `preferences.${USER_PREFERENCE_KEY_AVAILABILITY_FILTER}`);
    const subscriptionPrice = SUBSCRIPTION_PLAN_PRICE;

    let userData = {
        availabilityPreference,
        subscriptionPrice,
    };

    if (profileId) {
        const boxStatus = get(rootState[BOX_MODULE_NAME], 'boxOrderStatus');
        const boxBalance = get(rootState[BOX_MODULE_NAME], 'boxInfo.boxBalance');
        const daysToNextBillingDate = getDiffDays(nextBillingCycle);

        userData = {
            ...userData,
            profileId,
            subscriptionStatus,
            boxStatus,
            boxBalance,
            daysToNextBillingDate,
        };
    }

    return userData;
};

export default function createRentAnalytics(config) {
    return merge(createAnalytics(config), {

        state: {
            user: {
                availabilityPreference: null,
                daysToNextBillingDate: null,
            },
        },

        /* eslint-disable no-param-reassign */
        mutations: {
            [TRACK_SUBSCRIPTION_CREATE_COMPLETED](state, subscriptionData) {
                state.user = {
                    ...state.user,
                    planId: subscriptionData.product?.planId,
                    subscriptionStatus: subscriptionData.user?.subscriptionStatus,
                };

                trackEvent(state, {
                    action: 'purchase',
                    ...subscriptionData.purchase,
                }, false);

                trackEvent(state, { action: 'subscribe' }, false);
            },
            [TRACK_SIGN_UP_COMPLETED](state, {
                profileId,
                traceId,
                smsOptIn,
                signUpLocation,
            }) {
                state.user = {
                    ...state.user,
                    profileId,
                };

                const onboardingSignUpLocations = [
                    ACCOUNT_CREATE_WEB_VIEW,
                    ACCOUNT_CREATE,
                    WAITLIST_LOCATIONS.ios,
                    WAITLIST_LOCATIONS.web,
                ];

                trackEvent(state, {
                    action: 'sign_up',
                    traceId,
                    smsOptIn,
                    type: onboardingSignUpLocations.includes(signUpLocation) ?
                        SIGN_UP_TYPES.ONBOARDING : SIGN_UP_TYPES.GIFT_CARD,
                });
            },
        },

        /* eslint-disable no-param-reassign */
        actions: {
            [TRACK_REFERRAL_CLICKED]({ state }, type) {
                trackEvent(state, { action: 'send_referral', type }, false);
            },
            [TRACK_REFERRAL_SENT]({ state }) {
                trackEvent(state, { action: 'email_share_success' }, false);
            },
            [TRACK_SUBLIST_LINK_SHARED]({ dispatch }, { destination }) {
                dispatch(TRACK_LINK_SHARED, {
                    type: SELECT_CONTENT_TYPES.SUBLIST_LINK,
                    destination,
                });
            },
            [TRACK_LINK_SHARED]({ state }, {
                destination,
                type = SELECT_CONTENT_TYPES.REFERRAL_LINK,
            }) {
                trackEvent(state, {
                    action: 'select_content',
                    type,
                    destination,
                }, false);
            },
            [TRACK_SUBSCRIPTION_COUPON_REDEEMED]({ state }, { location }) {
                trackEvent(state, { action: 'redeem_subscription_coupon', location }, false);
            },
            [TRACK_SUBSCRIPTION_STATUS_UPDATED]({ state }, eventInfo) {
                const eventObject = pickBy(eventInfo, identity);

                trackEvent(state, eventObject, false);
            },
            [TRACK_BOX_ITEM_STATUS_UPDATED]({ state }, eventInfo) {
                const { action, productAnalyticsData } = eventInfo;

                trackEvent(state, {
                    action,
                    ...productAnalyticsData,
                }, false);
            },
            [TRACK_PROFILE_UPDATED]({ state }) {
                trackEvent(state, { action: 'updated_profile_preferences' });
            },
            [TRACK_SHIPPING_ADDRESS_UPDATED]({ state }) {
                trackEvent(state, { action: 'updated_shipping_address' }, false);
            },
            [TRACK_EMAIL_UPDATED]({ state }) {
                trackEvent(state, { action: 'updated_email_address' }, false);
            },
            [TRACK_CATALOG_GRID_LAYOUT_UPDATED]({ state }, { type }) {
                trackEvent(state, {
                    action: 'updated_product_view',
                    type,
                }, false);
            },
            [TRACK_CATALOG_AVAILABILITY_UPDATED]({ dispatch }, { label }) {
                dispatch(TRACK_PRODUCT_FILTER_UPDATED, {
                    type: PRODUCT_FILTER_TYPES.AVAILABILITY,
                    filterValue: label,
                });
            },
            [TRACK_BOX_ITEM_OUT_OF_STOCK]({ state }, { type, orderLineCount }) {
                trackEvent(state, {
                    action: 'out_of_stock_alert',
                    type,
                    orderLineCount,
                }, false);
            },
            [TRACK_PRODUCT_TOUR_VIEWED]({ state }, { type }) {
                trackEvent(state, { action: 'view_product_tour', type }, false);
            },
            [TRACK_CHECK_FOR_DROP_OFF]({ state }, { action }) {
                trackEvent(state, { action }, false);
            },
            [TRACK_BONUS_ITEM]({ state }, { action, type }) {
                trackEvent(state, { action, type }, false);
            },
            [TRACK_RENEW_EARLY]({ state }, { action }) {
                trackEvent(state, { action }, false);
            },
            [TRACK_REPRINT_LABEL]({ state }, { value }) {
                trackEvent(state, { action: 'reprint_shipping_label', quantity: value }, false);
            },
            [TRACK_REQUEST_BOX]({ state }, { boxId }) {
                trackEvent(state, { action: 'request_box', boxId }, false);
            },
            [TRACK_REVIEW_PHOTO_CLICK]({ state }, { destination }) {
                trackEvent(state, {
                    action: 'select_content',
                    type: SELECT_CONTENT_TYPES.REVIEW_PHOTO,
                    destination,
                }, false);
            },
            [TRACK_BOX_LINK_CLICKED]({ state }, { destination, contentTitle }) {
                trackEvent(state, {
                    action: 'select_content',
                    type: SELECT_CONTENT_TYPES.BOX_LINK,
                    destination,
                    contentTitle,
                }, false);
            },
            [TRACK_DELETE_SUBLIST]({ state }) {
                trackEvent(state, { action: 'delete_sublist' }, false);
            },
            [TRACK_CREATE_SUBLIST]({ state }) {
                trackEvent(state, { action: 'create_sublist' }, false);
            },
            [TRACK_REVIEW_FILTER_UPDATED]({ dispatch }, { label }) {
                dispatch(TRACK_PRODUCT_FILTER_UPDATED, {
                    type: REVIEW_FILTER_TYPES.FILTER,
                    filterValue: label,
                });
            },
            [TRACK_REVIEW_SORT_UPDATED]({ dispatch }, { label }) {
                dispatch(TRACK_PRODUCT_FILTER_UPDATED, {
                    type: REVIEW_FILTER_TYPES.SORT,
                    filterValue: label,
                });
            },
            [TRACK_SHARE_CONTENT]({ state }, { destination, method, type }) {
                trackEvent(state, {
                    action: 'share_content',
                    type,
                    method,
                    destination,
                }, false);
            },
            [TRACK_VIEW_SIMILAR_CLICKED]({ state }, { destination }) {
                trackEvent(state, {
                    action: 'select_content',
                    type: SELECT_CONTENT_TYPES.SIMILIAR_ITEMS_LINK,
                    destination,
                }, false);
            },
            [TRACK_SIGN_UP_STARTED]({ state }, { type }) {
                trackEvent(state, {
                    action: 'sign_up_begin',
                    type: type ?? SIGN_UP_TYPES.ONBOARDING,
                }, false);
            },
            [TRACK_REDEEM_GIFT_CARD]({ state }, { type, giftCardAmount }) {
                trackEvent(state, {
                    action: 'redeem_gift_card',
                    type,
                    giftCardAmount: convertCentsToDollars(giftCardAmount),
                }, false);
            },
            [TRACK_ADD_TO_PROSPECT_BOX_CLICK]({ state }, { destination }) {
                trackEvent(state, {
                    action: 'select_content',
                    type: SELECT_CONTENT_TYPES.ADD_TO_PROSPECT_BOX,
                    destination,
                }, false);
            },
            [TRACK_CURATION_CLICK]({ state }, { curation, index, randomizeCurationNavItems, filterString }) {
                trackEvent(state, {
                    action: 'select_content',
                    type: SELECT_CONTENT_TYPES.CURATION_NAV_ITEM,
                    curation,
                    index: `${index}`,
                    randomizeCurationNavItems,
                    filterString,
                }, false);
            },
        },
        getters: {
            userData() {
                return rootState => ({ ...getUserData(rootState), ...getAppUserData(rootState) });
            },
        },
    });
}
