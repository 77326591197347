import { defineAsyncComponent, markRaw } from 'vue';

import {
    GLOBAL_MODALS,
    getGlobalModalHashEvents,
    GLOBAL_MODAL_QUERY_PROPS,
} from '~coreModules/modals/js/modal-constants';

import { EMAIL_OPT_IN_LOCATIONS } from '~modules/core/js/constants';

// Whitelisted query params which can be passed to a modal's props when launching via a hash-event
// should start with 'modal-' what comes after will be the prop name
// if the prop is not defined on the modal component, it will be ignored
// ex: ?modal-skuId=abc will populate a modals skuId prop as abc
export const MODAL_QUERY_PROPS = {
    ...GLOBAL_MODAL_QUERY_PROPS,
    boxId: 'modal-boxId',
    purchaseCouponCode: 'modal-purchaseCouponCode',
};

// List of all available modals
// add title to the GLOBAL_REAL_TIME_SLUGS
export const MODALS = {
    ...GLOBAL_MODALS,
    anonymousEmailSubscribe: {
        ...GLOBAL_MODALS.anonymousEmailSubscribe,
        props: {
            isModal: true,
            optInLocation: EMAIL_OPT_IN_LOCATIONS.MODAL,
        },
    },
    boxGenericContentful: {
        id: 'box-generic-contentful',
        path: '/rent/box/generic-contentful',
        analytics: {
            pageCategory: 'box',
            pageType: 'box',
        },
        title: 'box__title',
        styleType: 'RIGHT',
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "boxContentfulSlide" */
                '~modules/box/components/BoxContentfulSlide.vue'))),
        isFullBleedContent: true,
    },
    boxAddOnItemInfo: {
        id: 'box-add-on-item-info',
        path: '/rent/box/add-on-item-info',
        analytics: {
            pageCategory: 'box',
            pageType: 'box',
        },
        title: 'box__title',
        styleType: 'RIGHT',
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "boxContentfulSlide" */
                '~modules/box/components/BoxContentfulSlide.vue'))),
        props: {
            contentfulSlug: 'box-add-on-item-info',
        },
        isFullBleedContent: true,
    },
    boxBonusItemsInfo: {
        id: 'box-bonus-items-info',
        path: '/rent/box/bonus-items-info',
        analytics: {
            pageCategory: 'box',
            pageType: 'box',
        },
        title: 'box__title',
        styleType: 'RIGHT',
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "boxContentfulSlide" */
                '~modules/box/components/BoxContentfulSlide.vue'))),
        props: {
            contentfulSlug: 'box-bonus-items-info',
        },
        isFullBleedContent: true,
    },
    boxBonusItemSlide: {
        id: 'box-bonus-item-slide',
        path: '/rent/box/bonus-item-slide',
        analytics: {
            pageCategory: 'box',
            pageType: 'box',
        },
        title: 'box__bonusItemPurchase--heading',
        styleType: 'RIGHT',
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "box-bonus-item-slide" */
                '~modules/box/components/BoxBonusItemSlide.vue'))),
        isFullBleedContent: true,
    },
    boxConfirmShipping: {
        id: 'box-confirm-shipping-slide',
        path: '/rent/box/confirm-shipping',
        analytics: {
            pageCategory: 'box',
            pageType: 'box',
        },
        title: 'box__reviewBox--title',
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "boxConfirmShipmentSlide" */
                '~modules/box/components/BoxConfirmShipmentSlide.vue'))),
        isFullBleedContent: true,
        styleType: 'RIGHT',
        isLight: false,
    },
    boxGetAnswers: {
        id: 'box-get-answers',
        path: '/rent/box/get-answers',
        analytics: {
            pageCategory: 'box',
            pageType: 'box',
        },
        title: 'box__title',
        styleType: 'RIGHT',
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "boxContentfulSlide" */
                '~modules/box/components/BoxContentfulSlide.vue'))),
        props: {
            contentfulSlug: 'box-get-answers',
        },
        isFullBleedContent: true,
    },
    boxHome: {
        id: 'box-home-slide',
        path: '/rent/box',
        analytics: {
            pageCategory: 'box',
            pageType: 'box',
        },
        title: 'box__title',
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "boxHomeSlide" */
                '~modules/box/components/BoxHomeSlide.vue'))),
        isFullBleedContent: true,
        styleType: 'RIGHT',
        isLight: false,
        focusFirstElOnLoad: false,
    },
    boxHowToSwap: {
        id: 'box-how-to-swap',
        path: '/rent/box/how-to-swap',
        analytics: {
            pageCategory: 'box',
            pageType: 'box',
        },
        title: 'box__title',
        styleType: 'RIGHT',
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "boxContentfulSlide" */
                '~modules/box/components/BoxContentfulSlide.vue'))),
        props: {
            contentfulSlug: 'box-how-to-swap',
        },
        isFullBleedContent: true,
    },
    boxPurchaseSlide: {
        id: 'box-purchase-slide',
        path: '/rent/box/purchase',
        analytics: {
            pageCategory: 'box',
            pageType: 'box',
        },
        title: 'box__purchasePreview--title',
        styleType: 'RIGHT',
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "boxPurchaseSlide" */
                '~modules/box/components/BoxPurchaseSlide.vue'))),
        isFullBleedContent: true,
    },
    boxPurchaseSuccessSlide: {
        id: 'box-purchase-success-slide',
        path: '/rent/box/purchase/success',
        analytics: {
            pageCategory: 'box',
            pageType: 'box',
        },
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "boxPurchaseSuccessSlide" */
                '~modules/box/components/BoxPurchaseSuccessSlide.vue'))),
        title: 'global__success',
        styleType: 'RIGHT',
        isFullBleedContent: true,
        isClosingSlide: true,
    },
    boxRenewEarlyInfo: {
        id: 'box-renew-early-info',
        path: '/rent/box/renew-early-info',
        analytics: {
            pageCategory: 'box',
            pageType: 'box',
        },
        title: 'box__title',
        styleType: 'RIGHT',
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "boxContentfulSlide" */
                '~modules/box/components/BoxContentfulSlide.vue'))),
        props: {
            contentfulSlug: 'box-renew-early-info',
        },
        isFullBleedContent: true,
    },
    boxRenewEarlySlide: {
        id: 'box-renew-early-slide',
        path: '/rent/box/renew-early',
        analytics: {
            pageCategory: 'box',
            pageType: 'box',
        },
        title: 'box__purchasePreview--title',
        styleType: 'RIGHT',
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "boxRenewEarlySlide" */
                '~modules/box/components/BoxRenewEarlySlide.vue'))),
    },
    boxWhatsNext: {
        id: 'box-whats-next',
        path: '/rent/box/whats-next',
        analytics: {
            pageCategory: 'box',
            pageType: 'box',
        },
        title: 'box__title',
        styleType: 'RIGHT',
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "boxContentfulSlide" */
                '~modules/box/components/BoxContentfulSlide.vue'))),
        props: {
            contentfulSlug: 'box-whats-next',
        },
        isFullBleedContent: true,
    },
    boxHomeSubscriberGate: {
        id: 'box-home-subscriber-gate',
        path: '/rent/box/box-home-subscriber-gate',
        analytics: {
            pageCategory: 'box',
            pageType: 'box',
        },
        title: 'box__title',
        styleType: 'RIGHT',
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "boxContentfulSlide" */
                '~modules/box/components/BoxContentfulSlide.vue'))),
        props: {
            contentfulSlug: 'box-home-subscriber-gate-web',
        },
        isFullBleedContent: true,
    },
    boxCheckForDropOff: {
        id: 'box-check-for-drop-off',
        path: '/rent/box/box-check-for-drop-off',
        analytics: {
            pageCategory: 'box',
            pageType: 'box',
        },
        title: 'box__title',
        styleType: 'RIGHT',
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "boxCheckForDropOff" */
                '~modules/box/components/BoxCheckForDropOff.vue'))),
        isFullBleedContent: true,
    },
    boxCheckForDropOffAnimation: {
        id: 'check-for-drop-off',
        path: '/rent/box/check-for-drop-off',
        analytics: {
            pageCategory: 'box',
            pageType: 'box',
        },
        title: 'box__checkForDropOffAnimation--pageTitle',
        styleType: 'RIGHT',
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "boxCheckForDropOffAnimation" */
                '~modules/box/components/BoxCheckForDropOffAnimation.vue'))),
        isFullBleedContent: true,
        isClosingSlide: true,
    },
    boxCheckForDropOffFailed: {
        id: 'box-check-for-drop-off-failed',
        path: '/rent/box/box-check-for-drop-off-failed',
        analytics: {
            pageCategory: 'box',
            pageType: 'box',
        },
        title: 'box__title',
        styleType: 'RIGHT',
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "boxContentfulSlide" */
                '~modules/box/components/BoxContentfulSlide.vue'))),
        props: {
            contentfulSlug: 'box-check-for-drop-off-failed',
        },
        isFullBleedContent: true,
        isClosingSlide: true,
    },
    catalogFilterGroups: {
        id: 'catalog-filter-groups-menu',
        path: '/rent/filters',
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "filterGroupMenu" */
                '~modules/catalog/components/FilterGroupMenu.vue'))),
        isFullBleedContent: true,
        styleType: 'LEFT',
    },
    catalogFilterOptions: {
        id: 'catalog-filter-options-menu',
        path: '/rent/filters/options',
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "filterGroupMenu" */
                '~modules/catalog/components/FilterOptionsMenu.vue'))),
        isFullBleedContent: true,
        styleType: 'LEFT',
    },
    catalogSizeMenu: {
        id: 'catalog-size-menu',
        path: '/rent/filters/size',
        title: 'browse__size--title',
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "catalogSizeMenu" */
                '~modules/catalog/components/CatalogSizeMenu.vue'))),
        isFullBleedContent: true,
        styleType: 'LEFT',
    },
    catalogSkuSelector: {
        id: 'catalog-sku-selector-modal',
        path: '/rent/sku-selector',
        title: 'browse__selectSize',
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "catalog-sku-selector-drawer" */
                '~modules/catalog/components/CatalogSkuSelectorDrawer.vue'))),
        styleType: 'BOTTOM-SHORT',
        isLight: false,
        isFullBleedContent: true,
    },
    closetCreateNewListModal: {
        id: 'closet-create-new-list-modal',
        path: '/rent/closet/lists/add-list',
        analytics: {
            pageCategory: 'closet',
            pageType: 'closet',
        },
        title: 'sublists__addNewList--header',
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "closetCreateNewListModal" */
                '~routes/closet/components/ClosetCreateNewListModal.vue'))),
        isFullBleedContent: true,
        styleType: 'BOTTOM',
        isLight: false,
    },
    closetAddToListModal: {
        id: 'closet-add-to-list-modal',
        path: '/rent/closet/lists/add-to-list',
        analytics: {
            pageCategory: 'closet',
            pageType: 'closet',
        },
        title: 'sublists__addToList--header',
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "closetAddToListModal" */
                '~routes/closet/components/ClosetAddToListModal.vue'))),
        isFullBleedContent: true,
        focusFirstElOnLoad: false,
        styleType: 'BOTTOM',
        isLight: false,
    },
    closetDeleteListModal: {
        id: 'closet-delete-list-modal',
        path: '/rent/closet/lists/delete-list',
        analytics: {
            pageCategory: 'closet',
            pageType: 'closet',
        },
        title: 'sublists__deleteList--header',
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "closetDeleteListModal" */
                '~routes/closet/components/ClosetDeleteListModal.vue'))),
        isFullBleedContent: false,
        focusFirstElOnLoad: false,
        styleType: 'BOTTOM',
        isLight: false,
    },
    closetEditListModal: {
        id: 'closet-edit-list-modal',
        path: '/rent/closet/lists/rename-list',
        analytics: {
            pageCategory: 'closet',
            pageType: 'closet',
        },
        title: 'sublist__renameList--header',
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "closetEditListModal" */
                '~routes/closet/components/ClosetEditListModal.vue'))),
        isFullBleedContent: false,
        focusFirstElOnLoad: false,
        styleType: 'BOTTOM',
        isLight: false,
    },
    closetNavModal: {
        id: 'closet-nav-modal',
        path: '/rent/closet',
        analytics: {
            pageCategory: 'closet',
            pageType: 'closet',
        },
        title: 'sublists__navLists--header',
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "closetNavModal" */
                '~routes/closet/components/ClosetNavModal.vue'))),
        isFullBleedContent: true,
        focusFirstElOnLoad: false,
        styleType: 'RIGHT',
        isLight: false,
    },
    closetEditUsernameModal: {
        id: 'closet-edit-username-modal',
        path: '/rent/closet/edit-username',
        title: 'sublists__usernameDrawer--drawerTitle',
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "usernameModal" */
                '~modules/modals/components/UsernameModal.vue'))),
        isLight: false,
        isAuthRequired: true,
    },
    closetListShareModal: {
        id: 'closet-list-share-modal',
        path: '/rent/closet/share-list',
        title: 'sublists__shareList--drawerTitle',
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "closetListShareModal" */
                '~routes/closet/components/ClosetListShareModal.vue'))),
        isLight: false,
        isContentCentered: true,
        focusFirstElOnLoad: true,
    },
    closetJoinModal: {
        ...GLOBAL_MODALS.joinModal,
        id: 'closet-join-modal',
        path: '/rent/closet/join', // TODO: what should this be?
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "closetJoinModal" */
                '~routes/closet/components/ClosetJoinModal.vue'))),
    },
    contentCardsPencilDrawerOne: {
        id: 'content-cards-pencil-drawer-one',
        path: '/rent/content-cards-pencil-drawer-one',
        analytics: {
            pageCategory: 'content-slot',
            pageType: 'content-slot',
        },
        title: 'contentCards__infoDrawerOne--header',
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "contentfulContentModal" */
                '~coreModules/modals/components/ContentfulContentModal.vue'))),
        props: {
            contentfulSlug: 'content-cards-pencil-drawer-one',
            contentfulContentBlock: markRaw(
                defineAsyncComponent(() => import(
                    /* webpackChunkName: "contentfulContentBlock" */
                    '~modules/contentful/components/ContentfulContentBlock.vue'))),
        },
        styleType: 'BOTTOM',
        isLight: false,
        isFullBleedContent: true,
    },
    contentCardsPencilDrawerTwo: {
        id: 'content-cards-pencil-drawer-two',
        path: '/rent/content-cards-pencil-drawer-two',
        analytics: {
            pageCategory: 'content-slot',
            pageType: 'content-slot',
        },
        title: 'contentCards__infoDrawerTwo--header',
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "contentfulContentModal" */
                '~coreModules/modals/components/ContentfulContentModal.vue'))),
        props: {
            contentfulSlug: 'content-cards-pencil-drawer-two',
            contentfulContentBlock: markRaw(
                defineAsyncComponent(() => import(
                    /* webpackChunkName: "contentfulContentBlock" */
                    '~modules/contentful/components/ContentfulContentBlock.vue'))),
        },
        styleType: 'BOTTOM',
        isLight: false,
        isFullBleedContent: true,
    },
    editEmail: {
        id: 'edit-email-modal',
        path: '/rent/edit-email',
        title: 'profile__editEmail--title',
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "edit-email-drawer" */
                '~routes/account/components/profile/EditEmailDrawer.vue'))),
        isContentCentered: true,
        isLight: false,
    },
    editName: {
        id: 'edit-name-modal',
        path: '/rent/edit-name',
        title: 'account__editNameUsername--title',
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "edit-name-drawer" */
                '~routes/account/components/profile/EditNameDrawer.vue'))),
        isLight: false,
        isContentCentered: true,
    },
    editPayment: {
        id: 'edit-payment-modal',
        path: '/rent/edit-payment',
        title: 'payment__editPaymentInfo--title',
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "edit-billing-drawer" */
                '~routes/account/components/billing/EditBillingDrawer.vue'))),
        isLight: false,
        isFullBleedContent: true,
    },
    editProfile: {
        id: 'edit-profile-modal',
        path: '/rent/edit-profile',
        title: 'profile__editProfile--title',
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "edit-profile-drawer" */
                '~routes/account/components/profile/EditProfileDrawer.vue'))),
        isLight: false,
    },
    editReviewSettings: {
        id: 'edit-review-settings-modal',
        path: '/rent/edit-review-settings',
        title: 'profile__editDisplayOnReviews--title',
        isLight: false,
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "edit-review-settings-drawer" */
                '~routes/account/components/profile/EditReviewSettingsDrawer.vue'))),
    },
    editShippingAddress: {
        id: 'edit-shipping-address-modal',
        path: '/rent/edit-shipping-address',
        title: 'profile__editShippingAddress—title',
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "edit-shipping-address-drawer" */
                '~routes/account/components/profile/EditShippingAddressDrawer.vue'))),
        isLight: false,
    },
    editNotifications: {
        id: 'edit-notifications-modal',
        path: '/rent/edit-notifications',
        title: 'communication__preferences--header',
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "edit-notifications-drawer" */
                '~routes/account/components/profile/EditNotificationsDrawer.vue'))),
        isLight: false,
        isAuthRequired: true,
    },
    giftCardDrawerContent: {
        id: 'gift-card-drawer-content',
        path: '/rent/gift-cards/gift-card-drawer-content',
        analytics: {
            pageCategory: 'gift-cards',
            pageType: 'gift-cards',
        },
        title: 'giftCards__infoModal--header',
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "contentfulContentModal" */
                '~coreModules/modals/components/ContentfulContentModal.vue'))),
        props: {
            contentfulSlug: 'gift-card-drawer-content',
            contentfulContentBlock: markRaw(
                defineAsyncComponent(() => import(
                    /* webpackChunkName: "contentfulContentBlock" */
                    '~modules/contentful/components/ContentfulContentBlock.vue'))),
        },
        styleType: 'BOTTOM',
        isLight: false,
        isFullBleedContent: true,
    },
    howToRedeemBonusItem: {
        id: 'how-to-redeem-bonus-item',
        path: '/rent/how-to-redeem-bonus-item',
        analytics: {
            pageCategory: 'account',
            pageType: 'account',
        },
        title: 'redeem__bonusItemRedeemModal--title',
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "ContentfulContentModal" */
                '~coreModules/modals/components/ContentfulContentModal.vue'))),
        props: {
            contentfulSlug: 'how-to-redeem-bonus-item',
            contentfulContentBlock: markRaw(
                defineAsyncComponent(() => import(
                    /* webpackChunkName: "ContentfulContentBlock" */
                    '~modules/contentful/components/ContentfulContentBlock.vue'))),
        },
        styleType: 'BOTTOM',
        isLight: false,
        isFullBleedContent: true,
        isAuthRequired: true,
    },
    howToRedeemItemCoupon: {
        id: 'how-to-redeem-item-coupon',
        path: '/rent/how-to-redeem-item-coupon',
        analytics: {
            pageCategory: 'account',
            pageType: 'account',
        },
        title: 'redeem__itemCouponRedeemModal--title',
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "ContentfulContentModal" */
                '~coreModules/modals/components/ContentfulContentModal.vue'))),
        props: {
            contentfulSlug: 'how-to-redeem-item-coupon',
            contentfulContentBlock: markRaw(
                defineAsyncComponent(() => import(
                    /* webpackChunkName: "ContentfulContentBlock" */
                    '~modules/contentful/components/ContentfulContentBlock.vue'))),
        },
        styleType: 'BOTTOM',
        isLight: false,
        isFullBleedContent: true,
        isAuthRequired: true,
    },
    marketingSmsSubscribe: {
        id: 'rent-marketing-sms-subscribe',
        path: '/rent/rent-marketing-sms-subscribe',
        analytics: {
            pageCategory: 'in-app-campaign',
            pageType: 'in-app-campaign',
        },
        title: 'marketingSmsOptIn__modal--title',
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "marketingSmsSubscribe" */
                '~modules/core/components/ui/MarketingSmsSubscribe.vue'))),
        styleType: 'BOTTOM',
        isLight: false,
        isFullBleedContent: true,
    },
    operationalSmsSubscribe: {
        id: 'rent-operational-sms-subscribe',
        path: '/rent/rent-operational-sms-subscribe',
        analytics: {
            pageCategory: 'in-app-campaign',
            pageType: 'in-app-campaign',
        },
        title: 'operationalSmsOptIn__modal--title',
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "operationalSmsSubscribe" */
                '~modules/core/components/ui/OperationalSmsSubscribe.vue'))),
        styleType: 'BOTTOM',
        isLight: false,
        isFullBleedContent: true,
    },
    rewardsHistory: {
        id: 'rewards-history',
        path: '/rent/rewards-history',
        title: 'redeem__referralDrawer--header',
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "RewardsHistoryDrawer" */
                '~routes/account/components/redeem/RewardsHistoryDrawer.vue'))),
        isLight: false,
    },
    navL1Menu: {
        id: 'navigation-menu-l1',
        path: '/rent/navigation',
        analytics: {
            pageCategory: 'navigation',
            pageType: 'navigation',
        },
        title: 'Nuuly',
        titleImage: 'logo.svg',
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "NavigationMenuL1" */
                '~modules/nav/components/NavigationMenu.vue'))),
        isFullBleedContent: true,
        focusFirstElOnLoad: false,
        styleType: 'LEFT',
        isLight: false,
    },
    pdpDetails: {
        id: 'product-details-modal',
        path: '/rent/product-details',
        title: 'product__productDetails--title',
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "ProductDetailsDrawer" */
                '~routes/product/components/ProductDetailsDrawer.vue'))),
        isFullBleedContent: true,
        isLight: false,
    },
    pdpReviewsCarousel: {
        id: 'product-reviews-carousel-modal',
        path: '/rent/reviews-carousel',
        title: 'reviews__imageCarousel--title',
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "ProductReviewsCarouselDrawer" */
                '~routes/product/components/ProductReviewsCarouselDrawer.vue'))),
        isFullBleedContent: true,
        isLight: false,
        focusFirstElOnLoad: false,
        hasInnerPadding: false,
        styleType: 'BOTTOM-FULLSCREEN',
    },
    pdpReviewsDrawer: {
        id: 'product-reviews-modal',
        path: '/rent/reviews',
        title: 'reviews__ratingsReviews--title',
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "ProductReviewsDrawer" */
                '~routes/product/components/ProductReviewsDrawer.vue'))),
        isFullBleedContent: true,
        isLight: false,
        focusFirstElOnLoad: false,
        styleType: 'BOTTOM-FULLSCREEN',
        hasInnerPadding: false,
    },
    pdpReviewsDrawerInCarousel: {
        id: 'product-reviews-modal-in-carousel',
        path: '/rent/reviews',
        title: 'reviews__ratingsReviews--title',
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "ProductReviewsDrawer" */
                '~routes/product/components/ProductReviewsDrawer.vue'))),
        isFullBleedContent: true,
        isLight: false,
        isClosingSlide: true,
        focusFirstElOnLoad: false,
        styleType: 'BOTTOM-FULLSCREEN',
        hasInnerPadding: false,
    },
    pdpReviewsPhotoGrid: {
        id: 'product-reviews-photo-grid',
        path: '/rent/reviews/photo-grid',
        analytics: {
            pageCategory: 'product',
            pageType: 'product',
        },
        title: 'reviews__customerPhotosGalleryDrawer--title',
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "productReviewsImageGalleryDrawer" */
                '~routes/product/components/ProductReviewsImageGalleryDrawer.vue'))),
        isFullBleedContent: true,
        isLight: false,
        focusFirstElOnLoad: false,
        styleType: 'BOTTOM-FULLSCREEN',
        hasInnerPadding: false,
    },
    prePurchaseInfo: {
        id: 'prepurchase-pdp-content',
        path: '/rent/prepurchase-pdp-content',
        title: 'product__prepurchase--learnMoreContentDrawerTitle',
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "contentfulContentModal" */
                '~coreModules/modals/components/ContentfulContentModal.vue'))),
        props: {
            contentfulSlug: 'prepurchase-pdp-content',
            contentfulContentBlock: markRaw(
                defineAsyncComponent(() => import(
                    /* webpackChunkName: "contentfulContentBlock" */
                    '~modules/contentful/components/ContentfulContentBlock.vue'))),
        },
        styleType: 'BOTTOM',
        isLight: false,
        isFullBleedContent: true,
    },
    prePurchaseCheckout: {
        id: 'prepurchase-checkout',
        path: '/rent/prepurchase-checkout',
        analytics: {
            pageCategory: 'prepurchase-checkout',
            pageType: 'prepurchase-checkout',
        },
        title: 'Nuuly',
        titleImage: 'logo.svg',
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "prePurchaseCheckout" */
                '~routes/product/components/PrePurchaseCheckoutModal.vue'))),
        styleType: 'FULLSCREEN',
        isLight: false,
        isAuthRequired: true,
        isFullBleedContent: true,
    },
    prePurchaseSuccess: {
        id: 'prepurchase-success',
        path: '/rent/prepurchase-success',
        analytics: {
            pageCategory: 'prepurchase-success',
            pageType: 'prepurchase-success',
        },
        title: 'Nuuly',
        titleImage: 'logo.svg',
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "prePurchaseSuccess" */
                '~routes/product/components/PrePurchaseSuccessModal.vue'))),
        styleType: 'FULLSCREEN',
        isLight: false,
        isAuthRequired: true,
        isFullBleedContent: true,
        isClosingSlide: true,
        isContentCentered: true,
    },
    promoCodeEntry: {
        id: 'promo-code-entry',
        path: '/rent/promo-code-entry',
        analytics: {
            pageCategory: 'account',
            pageType: 'account',
        },
        title: 'redeem__subscriptionPromoModalSlide1--title',
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "promoCodeEntrySlide" */
                '~routes/account/components/redeem/PromoCodeEntrySlide.vue'))),
        isFullBleedContent: true,
        isLight: false,
        isAuthRequired: true,
    },
    promoCodeApply: {
        id: 'promo-code-apply',
        path: '/rent/promo-code-apply',
        analytics: {
            pageCategory: 'account',
            pageType: 'account',
        },
        title: 'redeem__subscriptionPromoModalSlide1--title',
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "promoCodeApplySlide" */
                '~routes/account/components/redeem/PromoCodeApplySlide.vue'))),
        isFullBleedContent: true,
        isLight: false,
        isClosingSlide: true,
    },
    promoCodeRedeemed: {
        id: 'promo-code-redeemed',
        path: '/rent/promo-code-redeemed',
        analytics: {
            pageCategory: 'account',
            pageType: 'account',
        },
        title: 'redeem__subscriptionPromoModalSlide1--title',
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "promoCodeRedeemedSlide" */
                '~routes/account/components/redeem/PromoCodeRedeemedSlide.vue'))),
        isFullBleedContent: true,
        isLight: false,
    },
    shopTheLook: {
        id: 'shop-the-look',
        path: '/rent/shop-the-look',
        analytics: {
            pageCategory: 'content-shop-the-look',
            pageType: 'content-shop-the-look',
        },
        title: 'browse__shoppableImage--title',
        styleType: 'BOTTOM-SHORT',
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "shopTheLookDrawer" */
                '~modules/catalog/components/ShopTheLookDrawer.vue'))),
        isFullBleedContent: true,
        isLight: false,
    },
    viewReceipt: {
        id: 'view-receipt-modal',
        path: '/rent/view-receipt',
        title: 'order__viewReceipt--label',
        isLight: false,
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "view-receipt-drawer" */
                '~routes/account/components/billing/ViewReceiptDrawer.vue'))),
        focusFirstElOnLoad: false,
        isFullBleedContent: true,
    },
    authenticatedMenu: {
        id: 'authenticated-menu',
        path: '/rent/authenticated-menu',
        title: 'header__account--label',
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "headerAuthenticatedMenu" */
                '~modules/header/components/HeaderAuthenticatedMenu.vue'))),
        isFullBleedContent: true,
        styleType: 'RIGHT',
        isLight: false,
        focusFirstElOnLoad: false,
    },
    unauthenticatedMenu: {
        id: 'unauthenticated-menu',
        path: '/rent/unauthenticated-menu',
        title: 'header__account--label',
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "headerUnauthenticatedMenu" */
                '~modules/header/components/HeaderUnauthenticatedMenu.vue'))),
        isFullBleedContent: true,
        styleType: 'RIGHT',
        isLight: false,
        focusFirstElOnLoad: false,
    },
};

/* Modals which can be triggered via a hash in the URL */
export function getModalHashEvents(featureConfig) {
    return {
        ...getGlobalModalHashEvents(featureConfig),
        [`#${GLOBAL_MODALS.exampleModal.id}`]: {
            ...GLOBAL_MODALS.exampleModal,
            props: {
                exampleModalProp: 'RENT',
            },
        },
        [`#${MODALS.anonymousEmailSubscribe.id}`]: MODALS.anonymousEmailSubscribe,
        [`#${MODALS.boxAddOnItemInfo.id}`]: MODALS.boxAddOnItemInfo,
        [`#${MODALS.boxBonusItemsInfo.id}`]: MODALS.boxBonusItemsInfo,
        [`#${MODALS.boxGetAnswers.id}`]: MODALS.boxGetAnswers,
        [`#${MODALS.boxHome.id}`]: MODALS.boxHome,
        [`#${MODALS.boxHowToSwap.id}`]: MODALS.boxHowToSwap,
        [`#${MODALS.boxPurchaseSlide.id}`]: MODALS.boxPurchaseSlide,
        [`#${MODALS.boxRenewEarlyInfo.id}`]: MODALS.boxRenewEarlyInfo,
        [`#${MODALS.boxRenewEarlySlide.id}`]: MODALS.boxRenewEarlySlide,
        [`#${MODALS.boxWhatsNext.id}`]: MODALS.boxWhatsNext,
        [`#${MODALS.boxHomeSubscriberGate.id}`]: MODALS.boxHomeSubscriberGate,
        [`#${MODALS.boxCheckForDropOff.id}`]: MODALS.boxCheckForDropOff,
        [`#${MODALS.boxCheckForDropOffAnimation.id}`]: MODALS.boxCheckForDropOffAnimation,
        [`#${MODALS.closetNavModal.id}`]: MODALS.closetNavModal,
        [`#${MODALS.closetAddToListModal.id}`]: MODALS.closetAddToListModal,
        // NU_FEATURE: CONTENT_CARDS
        // https://urbnit.atlassian.net/browse/TYP-25489
        ...(featureConfig.contentCards && {
            [`#${MODALS.contentCardsPencilDrawerOne.id}`]: MODALS.contentCardsPencilDrawerOne,
            [`#${MODALS.contentCardsPencilDrawerTwo.id}`]: MODALS.contentCardsPencilDrawerTwo,
        }),
        [`#${MODALS.marketingSmsSubscribe.id}`]: MODALS.marketingSmsSubscribe,
        [`#${MODALS.operationalSmsSubscribe.id}`]: MODALS.operationalSmsSubscribe,
        [`#${MODALS.editNotifications.id}`]: MODALS.editNotifications,
        [`#${MODALS.promoCodeEntry.id}`]: MODALS.promoCodeEntry,
        [`#${MODALS.howToRedeemBonusItem.id}`]: MODALS.howToRedeemBonusItem,
        [`#${MODALS.howToRedeemItemCoupon.id}`]: MODALS.howToRedeemItemCoupon,
        [`#${MODALS.prePurchaseCheckout.id}`]: MODALS.prePurchaseCheckout,
        [`#${MODALS.prePurchaseInfo.id}`]: MODALS.prePurchaseInfo,
    };
}
