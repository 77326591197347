/* eslint-disable import/prefer-default-export */

import { isEmpty, pick } from 'lodash-es';

import runtimeConfig from '~config/config';

import { CATALOG_PAGE_SIZE } from '~modules/catalog/js/catalog-constants';
import { NUU_HEADER_RESPONSE_ID } from '~coreModules/core/js/constants';

const { apiUrls } = runtimeConfig;

export function getClosetItems({
    r15Svc,
    query = {},
    catalogFilterWhitelist = [],
    logger,
}) {
    if (isEmpty(catalogFilterWhitelist)) {
        logger.error('No catalogFilterWhitelist passed to GET getClosetItems');
    }

    const whitelistedQuery = pick(query, catalogFilterWhitelist);

    const options = {
        params: {
            ...whitelistedQuery,
            pageNumber: whitelistedQuery.pageNumber || 1,
            itemsPerPage: whitelistedQuery.itemsPerPage || CATALOG_PAGE_SIZE,
        },
    };

    return r15Svc.get(apiUrls.closetService, options);
}

export function getClosetListItems({
    r15Svc,
    query = {},
    catalogFilterWhitelist = [],
    listId,
}) {
    const whitelistedQuery = pick(query, catalogFilterWhitelist);

    const options = {
        params: {
            ...whitelistedQuery,
            pageNumber: whitelistedQuery.pageNumber || 1,
            itemsPerPage: whitelistedQuery.itemsPerPage || CATALOG_PAGE_SIZE,
        },
    };

    return r15Svc.get(`${apiUrls.closetService}/sublist/${listId}`, options);
}

export function addProductToCloset(r15Svc, choiceId, catalogResponseId = null) {
    const requestConfig = {
        ...(catalogResponseId && {
            headers: {
                [NUU_HEADER_RESPONSE_ID]: catalogResponseId,
            },
        }),
    };

    return r15Svc.put(`${apiUrls.closetService}/choice/${choiceId}`, {}, requestConfig);
}

export function removeProductFromCloset(r15Svc, choiceId) {
    return r15Svc.delete(`${apiUrls.closetService}/choice/${choiceId}`);
}

export function toggleProductClosetStatus(r15Svc, choiceId, isInCloset, catalogResponseId = null) {
    const asyncClosetAction = isInCloset ? removeProductFromCloset : addProductToCloset;

    return asyncClosetAction(r15Svc, choiceId, catalogResponseId)
        .then(() => Promise.resolve({ newClosetStatus: !isInCloset }));
}

export function getAllClosetLists(r15Svc) {
    return r15Svc.get(`${apiUrls.closetService}/sublists/preview`);
}

export function createNewClosetList(r15Svc, listName) {
    return r15Svc.post(`${apiUrls.closetService}/sublists`, {
        name: listName,
    });
}

export function editClosetListName(r15Svc, listId, newListName) {
    return r15Svc.put(`${apiUrls.closetService}/sublist/${listId}`, {
        name: newListName,
    });
}

export function addProductToClosetList(r15Svc, listId, choiceId, catalogResponseId = null) {
    const requestConfig = {
        ...(catalogResponseId && {
            headers: {
                [NUU_HEADER_RESPONSE_ID]: catalogResponseId,
            },
        }),
    };

    return r15Svc.put(`${apiUrls.closetService}/sublist/${listId}/choice/${choiceId}`, {}, requestConfig);
}

export function deleteProductFromClosetList(r15Svc, listId, choiceId) {
    return r15Svc.delete(`${apiUrls.closetService}/sublist/${listId}/choice/${choiceId}`);
}

export function deleteClosetList(r15Svc, listId) {
    return r15Svc.delete(`${apiUrls.closetService}/sublist/${listId}`);
}

export function deleteClosetListAndRemoveItemsFromCloset(r15Svc, listId) {
    return r15Svc.delete(`${apiUrls.closetService}/sublist${listId}/items`);
}
