import { merge } from 'lodash-es';

import createNavigation from '~coreModules/nav/js/nav-store';
import { BADGE_COLORS } from '~coreModules/core/js/badge-constants';
import { PROFILE_MODULE_NAME } from '~modules/profile/js/profile-store';
import { BOX_MODULE_NAME } from '~modules/box/js/box-store';
import {
    NAVIGATION_TREE_DEPTH,
    HEADER_NAV_ROOT_SLUG,
    PRE_PURCHASE_NAVIGATION_SLUG,
} from '~coreModules/nav/js/nav-constants';

import { buildNavigationTreeForSlug } from '~coreModules/nav/js/nav-utils';
import { userIsEligibleToViewContent } from '~modules/contentful/js/contentful-utils';

export { NAVIGATION_MODULE_NAME } from '~coreModules/nav/js/nav-store';

export const SET_SITE_NAVIGATION = 'SET_SITE_NAVIGATION';

export default function createRentNavigation(logger, config) {
    const coreNavigation = createNavigation(logger, config);

    return merge(coreNavigation, {
        getters: {
            /* hierarchical header navigation items */
            headerNav(state, getters, rootState, rootGetters) {
                const { flatNavigation } = state;
                const { appBaseUrl } = rootGetters;

                return buildNavigationTreeForSlug({
                    slug: HEADER_NAV_ROOT_SLUG,
                    flatNavigation,
                    logger,
                    depth: NAVIGATION_TREE_DEPTH,
                    appBaseUrl,
                });
            },
            eligibleNavContent: (state, getters, rootState, rootGetters) => {
                const { navigationContent } = state;
                const { loggedIn: isLoggedIn } = rootGetters;
                const effectiveSubscriptionStatus = rootGetters[`${PROFILE_MODULE_NAME}/effectiveSubscriptionStatus`];
                const hasRecentOrder = rootGetters[`${BOX_MODULE_NAME}/hasRecentOrder`];
                const boxOrderStatus = rootState[BOX_MODULE_NAME]?.boxOrderStatus;

                return navigationContent.filter(content => userIsEligibleToViewContent({
                    eligibilityRules: content.eligibility,
                    isLoggedIn,
                    boxOrderStatus,
                    subscriptionStatus: effectiveSubscriptionStatus,
                    hasRecentOrder,
                }));
            },
            prePurchaseNav(state, getters, rootState, rootGetters) {
                const { flatNavigation } = state;
                const { appBaseUrl } = rootGetters;

                const prePurchaseNav = buildNavigationTreeForSlug({
                    slug: PRE_PURCHASE_NAVIGATION_SLUG,
                    flatNavigation,
                    logger,
                    depth: NAVIGATION_TREE_DEPTH,
                    appBaseUrl,
                });

                if (prePurchaseNav?.length) {
                    prePurchaseNav[0].badge = {
                        badgeText: 'nav__prepurchase--tagComponentText',
                        icon: 'account--promo',
                        bkgColor: BADGE_COLORS.sunshine,
                    };
                }

                return prePurchaseNav;
            },
        },
    });
}
